<template>
  <div class="scheme-contain">
    <div class="affixion-contain">
      <Form :label-width="115">
        <div class="commom-panel-two">
          <div class="commom-panel-title">问题</div>
          <div class="commom-panel-main" v-for='(item, index) in doneProblem' :key="index">
            <Form class="search-form" :label-width="111">
              <Row>
                <Col span="24">
                  <Form-item label="咨询种类：">
                    <Cascader :data="problem" style="width: 230px;" v-model="item.idd" change-on-select></Cascader>
                  </Form-item>
                </Col>
              </Row>
              <Row>
                <Col span="24">
                  <Form-item label="详细情况：">
                    <Input v-model="item.detail_info" type="textarea" :rows="4" placeholder="请输入详细情况"></Input>
                  </Form-item>
                </Col>
              </Row>
              <Row>
                <Col span="24">
                  <Form-item label="是否上报解决：">
                    <Select v-model="item.should_report" placeholder="请选择" style="width: 230px;">
                      <Option :value="v.id" v-for="v in shouldList" :key="v.id">{{v.name}}</Option>
                    </Select>
                  </Form-item>
                </Col>
              </Row>
              <Row>
                <Col span="24">
                  <Form-item label="解决方案" v-if="item.should_report === '0'">
                    <Input v-model="item.reslove" type="textarea" :rows="4" placeholder="请输入解决方案"></Input>
                  </Form-item>
                </Col>
              </Row>
              <Row>
                <Col span="24">
                  <Form-item label="">
                    <Button @click="removePro(index)">删除</Button>
                  </Form-item>
                </Col>
              </Row>
            </Form>
          </div>
          <div class="add-problem">
            <a href="javascript:;" class="add-btn" @click="newProblem">新建问题单</a>
          </div>
        </div>
        <div class="symbol-box tac mt30">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <Button type="success" size="large" @click="handleSave">保存</Button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import servicesService from '@/services/servicesService';
import problemService from '@/services/problemService';
export default {
	data() {
		return {
			service_type: [],
			doneProblem: [
				{
					source: '门店',
					member_id: '',
					level1_id: '',
					level2_id: '',
					detail_info: '',
					should_report: '0',
					reslove: '',
					idd: [],
				},
			],
			problem: [],
			shouldList: [
				{
					id: '0',
					name: '已解决',
				},
				{
					id: '1',
					name: '上报',
				},
			],
			doneForm: {
				member_id: this.$route.params.member_id,
				data_string: '',
			},
		};
	},
	created() {
		servicesService.donePageServices().then((data) => {
			this.problem = data.problem_list;
			this.service_type = data.service_type;
		});
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleBack() {
			this.$router.go(-1);
		},
		newProblem() {
			this.doneProblem.push({
				source: '门店',
				member_id: '',
				level1_id: '',
				level2_id: '',
				detail_info: '',
				should_report: '0',
				reslove: '',
				idd: [],
			});
		},
		removePro(index) {
			this.doneProblem.splice(index, 1);
		},
		handleSave() {
			if (this.validateFormData()) {
				for (var i = 0; i < this.doneProblem.length; i++) {
					this.doneProblem[i].member_id = this.memberId;
					this.doneProblem[i].level1_id = this.doneProblem[i].idd[0]
						? this.doneProblem[i].idd[0]
						: '';
					this.doneProblem[i].level2_id = this.doneProblem[i].idd[1]
						? this.doneProblem[i].idd[1]
						: '';
					delete this.doneProblem[i].idd;
				}
				this.doneForm.data_string = JSON.stringify(this.doneProblem);
				problemService.submitMore(this.doneForm).then(() => {
					this.$router.push({
						path: '/base/member/detail/' + this.$route.params.member_id + '/problem/22',
					});
				});
			}
		},
		validateFormData() {
			for (var i = 0; i < this.doneProblem.length; i++) {
				if (!this.doneProblem[i].idd[0]) {
					this.$Message.warning('请选择各问题单的咨询种类');
					return false;
				}
				if (this.doneProblem[i].detail_info === '') {
					this.$Message.warning('请填写问题详情');
					return false;
				}
				if (this.doneProblem[i].should_report === '0' && !this.doneProblem[i].reslove) {
					this.$Message.warning('请填写解决方案');
					return false;
				}
			}
			return true;
		},
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
.scheme-contain .add-problem {
  padding: 10px 30px;
}
.scheme-contain .add-problem .add-btn {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding-left: 26px;
  background: url(@/assets/img/icon_add.png) no-repeat left;
}
.scheme-contain .commom-panel-two {
  margin: 0 10px;
}
.mt30 {margin-top: 30px;}
</style>
